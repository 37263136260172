:root {
  --blue: #4a728e;
  --cream: #dacdb8;
  --light-gray: #f6efe6;
  --dark-teal: #222e34;
  --light-gold: #f7d69d;
  --gold: #e2b479;
  --navy: #101323;
  --white: #fffcf6;
  --default-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

html {
  background-color: var(--white);
  color: var(--navy);
  font-family: 'Poppins', 'Arial', sans-serif;
  font-size: 10px; /* Set pixe; ratio for rem values */
  font-weight: 400;
}

input,
textarea,
button {
  font-family: 'Poppins', 'Arial', sans-serif;
}

input,
textarea {
  background-color: var(--white);
  border: 1px solid var(--navy);
  border-radius: 2px;
  padding: 0.5rem;
}

a {
  color: var(--navy);
}

a:visited {
  color: var(--dark-teal);
}

/* Add Ms Madi as fontStyle inline to help with loading time */
.cursive-font {
  font-size: 5.4rem;
  letter-spacing: 0.4px;
}

.cursive-font.small {
  font-size: 4.8rem;
}

.handwriting-font {
  font-family: 'Handlee', cursive;
}

p {
  font-size: 1.8rem;
  line-height: 2.6rem;
}

.banner {
  background: var(--navy);
  border: 3px solid var(--navy);
  border-radius: 4px;
  color: var(--white);
  margin: auto;
  margin-bottom: 3rem;
  padding: 3rem 0;
  text-align: center;

  .contents {
    max-width: 80%;
    margin: auto;
  }
}

.App {
  /* font-family: "Plus Jakarta Sans"; */
  font-size: 1.3rem;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--white);
}

.App-link {
  color: #61dafb;
}

.large {
  font-size: 2.4rem;
}

.medium {
  font-size: 2rem;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
