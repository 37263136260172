.top-navigation-mobile {
    height: 100%;
    position: relative;

    button {
        align-items: center;
        background-color: inherit;
        border: 1px solid var(--white);
        border-radius: 4px;
        color: var(--white);
        cursor: pointer;
        display: flex;
        font-size: 22px;
        justify-content: center;
        height: 100%;
        margin-left: 10px;
        width: 50px;

        &:is(:hover, :focus-visible) {
            background-color: var(--dark-teal);
            border-color: var(--white);
            color: var(--white);
        }

        &[aria-expanded='true'] {
            background-color: var(--light-gold);
            color: var(--navy);
            border-color: var(--navy);

            &:is(:hover, :focus-visible) {
                background-color: var(--gold);
            }
        }
    }

    .dropdown-menu {
        background-color: var(--white);
        border: 1px solid var(--navy);
        border-radius: 2px;
        box-shadow: var(--default-shadow);
        display: flex;
        flex-direction: column;
        font-size: 2rem;
        gap: 2.4rem;
        padding: 2.4rem 3.6rem;
        position: absolute;
        right: 0;
        width: max-content;

        a {
            color: var(--navy);
            text-decoration: none;

            &:is(:hover, :focus-visible) {
                text-decoration: underline;
            }
        }
    }

    @media (max-width: 576px) {
        .dropdown-menu {
            height: 43%;
            left: 0;
            position: fixed;
            width: 100%;
        }
    }
}