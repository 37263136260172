.home-page {
    .section {
        font-size: 1.8rem;
        padding: 14rem 6%;
        text-align: left;

        h2:not(.company-name) {
            font-size: 3.2rem;
            margin-top: 0;
        }
    }

    .quote {
        background-color: var(--cream);
        border: 1.6rem solid var(--navy);
        border-left: 0;
        border-right: 0;
        color: var(--navy);
        font-size: 3rem;
        padding: 4% 16%;
        margin: 0;
        position: relative;
        text-align: center;
        text-shadow: none;
        z-index: 1;

        .handwriting-font {
            margin: 0 auto 1.6rem;
            font-size: 2.8rem;
            line-height: 3.2rem;
        }

        .author-name {
            font-size: 2.8rem;
        }

        .author-credentials {
            font-size: 1.6rem;
        }
    }

    .caring {
        background: url("../../images/boy-with-wings.jpg") no-repeat center;
        background-attachment: fixed;
        background-size: cover;
        color: var(--white);

        .gold-text {
            text-shadow: 0 0 2px var(--navy), 0 0 1px var(--gold), 0 0 6px var(--gold);
        }

        .cursive-font {
            transition: color 0.5s ease;
        }
    }

    .ailments {
        background-color: var(--cream);
        border: 1.6rem solid var(--navy);
        border-left: 0;
        border-right: 0;
        text-align: center;

        h2 {
            margin-bottom: 6rem;
        }

        .who-we-help-links {
            display: flex;
            flex-wrap: wrap;
            gap: 50px;
            justify-content: center;
            margin: 0 auto;
            max-width: 1000px;

            a {
                align-items: center;
                background-color: var(--light-gray);
                border: 3px solid var(--navy);
                border-radius: 4px;
                box-shadow: var(--default-shadow);
                color: var(--navy);
                display: flex;
                flex-direction: column;
                font-size: 16px;
                gap: 16px;
                justify-content: center;
                height: 10rem;
                padding: 10px;
                text-align: center;
                text-decoration: none;
                width: 16rem;
                transition: all .4s ease;
                -webkit-transition: all .4s ease;

                &:hover {
                    box-shadow: var(--hover-shadow);
                    text-decoration: underline;
                }

                .icon {
                    font-size: 4rem;
                }
            }
        }
    }

    .contact {
        background-color: var(--navy);
        border: 0.4rem solid var(--gold);
        border-left: 0;
        border-right: 0;
        color: var(--light-gray);
        display: flex;
        flex-wrap: wrap;

        .contact-text {
            margin-left: 4%;
            max-width: 100%;
            width: 50%;
        }

        .handwriting-font {
            font-size: 2.4rem;
        }

        .contact-today-text {
            margin: 2.4rem 0 4.4rem;
        }

        .schedule-now {
            background-color: var(--blue);
            border-radius: 1.8rem;
            box-shadow: rgba(135, 110, 83, 0.3) 0 1px 2px 0px, rgba(135, 110, 83, 0.15) 0 2px 6px 2px;
            color: var(--white);
            font-size: 2rem;
            font-weight: 500;
            padding: 1.8rem 3rem;
            text-decoration: none;
            transition: all 0.5s ease;

            &:is(:hover, :focus-visible) {
                background-color: var(--gold);
                box-shadow: var(--light-gold) 0 0 8px 2px;
                color: var(--navy);
                text-decoration: underline;
            }
        }

        .helping-hand-img {
            object-fit: cover;
            object-position: top;
            width: 45%;
            max-height: 40rem;
        }
    }

    @media screen and (max-width: 491px) {
        .contact .helping-hand-img,
        .contact .contact-text {
            width: 100%;
        }

        .contact .contact-text {
            margin-left: 0.6rem;
            margin-top: 2rem;
        }

        .section {
            padding: 4rem 6%;
        }

        .quote {
            font-size: 2.6rem;
            padding: 6% 8%;
        }
    }
}