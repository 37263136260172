.who-we-help-page {
    .banner {
        font-size: 2.2rem;
        font-weight: 500;
    }

    .summary-passage {
        background: var(--light-gray);
        border: 3px solid var(--navy);
        border-radius: 0.4rem;
        padding: 1.6rem 6rem 4rem;

        h2 {
            font-size: 2.2rem;
            font-style: italic;
            padding-bottom: 1.6rem;
            text-align: center;
        }
    }

    .cards-container {
        display: flex;
        flex-wrap: wrap;
        gap: 3rem;
        justify-content: space-between;
        margin: 4rem 0;
    }
}