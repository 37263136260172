.contact-us-page {
    .banner {
        max-width: calc(100% - 6px);
        min-width: 60%;
    }

    .card-style {
        background: var(--light-gray);
        border-radius: 4px;
        border: 3px solid var(--navy);
        height: max-content;
        max-width: calc(100% - 8rem - 6px);
        padding: 2rem 4rem;
        width: max-content;
    }

    .two-col-display {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 3rem;
        justify-content: center;
        margin: 2rem auto;
        max-width: 100%;
        width: auto;

        .left-col {
            padding: 4rem 8rem;

            p {
                margin-top: 0;
            }
        }

        .right-col {
            display: flex;
            flex-direction: column;
            gap: 3rem;
            width: max-content;
            height: max-content;
            max-width: 100%;
            width: auto;

            h2 {
                font-size: 2.2rem;
                font-style: italic;
                margin-top: 0;
            }

            .contact-info {
                width: 30.8rem;
            }

            .office-hours {
                width: 30.8rem;

                .info-list li {
                    justify-content: space-between;
                }
            }

            .contact-info,
            .office-hours {
                .info-list {
                    font-size: 1.6rem;
                    padding-left: 0;

                    li {
                        align-items: center;
                        display: flex;
                        gap: 1.8rem;
                        padding: 0.6rem 0;
                    }
                }
            }
        }
    }

    .contact-form-container {
        .input-container {
            display: flex;
            flex-direction: column;
            margin-bottom: 1.2rem;

            label {
                font-size: 1.8rem;
                margin-bottom: 0.4rem;
                margin-top: 0.4rem;
            }

            input,
            textarea {
                font-size: 1.6rem;
            }

            textarea {
                max-height: 400px;
                min-height: 25px;
                resize: vertical;
            }
        }

        fieldset {
            display: flex;
            flex-direction: column;
            font-size: 1.8rem;
            gap: 4px;

            .checkbox-container {
                align-items: center;
                display: inline-flex;
                min-height: 32px;

                label {
                    cursor: pointer;
                    user-select: none;
                }

                input[type="checkbox"] {
                    cursor: pointer;
                    height: 15px;
                    width: 15px;
                }

                input[type="phone"],
                input[type="email"] {
                    margin-left: 8px;
                    max-width: 100%;
                }

                input[type="email"] {
                    min-width: 50%;
                }
            }
        }

        .submit-form {
            background-color: var(--navy);
            border: 1px solid var(--gold);
            border-radius: 0.8rem;
            box-shadow: rgba(135, 110, 83, 0.3) 0 1px 2px 0px, rgba(135, 110, 83, 0.15) 0 2px 6px 2px;
            color: var(--white);
            cursor: pointer;
            font-size: 1.8rem;
            font-weight: 500;
            line-height: 17px;
            margin-top: 1rem;
            padding: 13px 29px;
            text-decoration: none;
            transition: all 0.5s ease;

            &:is(:hover, :focus-visible) {
                background-color: var(--gold);
                box-shadow: rgb(135, 110, 83) 0 0 8px 2px;
                color: var(--navy);
            }
        }
    }

    .required,
    .location-details {
        font-size: 1.4rem;
    }

    @media screen and (max-width: 840px) {
        .two-col-display .left-col {
            padding: 2rem 4rem;
        }
    }
}