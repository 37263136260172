.page-header {
    align-items: center;
    color: var(--white);
    display: inline-flex;
    font-size: 1.6rem;
    height: 60px;
    left: 0;
    padding: 1rem 3%;
    position: fixed;
    width: calc(100% - 6%);
    top: 0;
    text-shadow: none;
    z-index: 10;

    .header-dim-overlay {
        background-color: var(--navy);
        height: 100%;
        left: 0;
        opacity: 0.94;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }

    .logo {
        color: var(--white);
        width: 5.5rem;
    }

    .company-name {
        font-weight: 600;
        margin: 0 0 0 1.6rem;
        margin-right: auto;
        max-width: 70%;
        text-wrap: balance;
    }

    .top-navigation {
        display: inline-flex;
        font-size: 1.8rem;
        gap: 2.8rem;

        a {
            color: var(--white);
            text-decoration: none;

            &:hover {
                color: var(--light-gold);
            }
        }
    }

    @media screen and (max-width: 576px) {
        .company-name {
            font-size: 2rem;
        }

        .logo {
            width: 5rem;
        }
    }

    @media screen and (max-width: 430px) {
        .company-name {
            font-size: 1.8rem;
        }
    }
}