.frame-layout {
    background-color: var(--cream);
    display: flex;
    flex-wrap: wrap;
    min-height: 100vh;
    width: 100vw;

    .body-slot {
        color: var(--navy);
        display: flex;
        flex-direction: column;
        margin: 12rem 2rem 2rem;
        padding: 0 2rem;
        width: calc(100% - 2rem);

        h1 {
            font-size: 3.6rem;
            margin: 0 0 2.4rem;
            text-align: center;
        }
    }

    @media screen and (max-width: 768px) {
        .body-slot {
            margin: 12rem 1rem 1rem;
            padding: 0;
        }
    }
}